var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
var ProjectsContext = createContext();
var MONTHS_DATA_FR = [
    { id: 1, name: "Janvier" },
    { id: 2, name: "Février" },
    { id: 3, name: "Mars" },
    { id: 4, name: "Avril" },
    { id: 5, name: "Mai" },
    { id: 6, name: "Juin" },
    { id: 7, name: "Juillet" },
    { id: 8, name: "Août" },
    { id: 9, name: "Septembre" },
    { id: 10, name: "Octobre" },
    { id: 11, name: "Novembre" },
    { id: 12, name: "Décembre" },
];
var MONTHS_DATA_EN = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
];
export var ProjectsProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var startDateField = "date_start_avis_timeline"; // date_publication_avis
    var endDateField = "date_end_avis_timeline"; // date_fermeture_avis
    var _c = __read(React.useState(0), 2), randQuery = _c[0], setRandQuery = _c[1];
    var _d = __read(React.useState("list"), 2), view = _d[0], setView = _d[1];
    var _e = __read(useState([]), 2), projects = _e[0], setProjects = _e[1];
    var _f = __read(useState({}), 2), rowsSearchData = _f[0], setRowsSearchData = _f[1];
    var _g = __read(useState(false), 2), advSearch = _g[0], setAdvSearch = _g[1];
    //year rang slider
    var _h = __read(useState(2022), 2), yearRange = _h[0], setYearRange = _h[1];
    var _j = __read(useState([2023]), 2), rangeValue = _j[0], setRangeValue = _j[1];
    //Filter State
    var _k = __read(useState([]), 2), selectedProjectsTypes = _k[0], setSelectedProjectsTypes = _k[1];
    var _l = __read(useState([]), 2), projectsTypes = _l[0], setProjectsTypes = _l[1];
    var _m = __read(useState([]), 2), projectsJesuisJechercheTypes = _m[0], setProjectsJesuisJechercheTypes = _m[1];
    var _o = __read(useState([]), 2), categories = _o[0], setCategories = _o[1];
    var _p = __read(useState([]), 2), selectedCategories = _p[0], setSelectedCategories = _p[1];
    var _q = __read(useState(0), 2), minValue = _q[0], setMinValue = _q[1];
    var _r = __read(useState(100), 2), maxValue = _r[0], setMaxValue = _r[1];
    var _s = __read(useState(null), 2), montantMaxValue = _s[0], setMontantMaxValue = _s[1];
    var _t = __read(useState([]), 2), mrc = _t[0], setMrc = _t[1];
    var _u = __read(useState([]), 2), selectedMrc = _u[0], setSelectedMrc = _u[1];
    var _v = __read(useState([]), 2), region = _v[0], setRegion = _v[1];
    var _w = __read(useState([]), 2), selectedRegion = _w[0], setSelectedRegion = _w[1];
    //search State
    var _x = __read(useState(""), 2), searchedTerm = _x[0], setSearchedTerm = _x[1];
    var _y = __read(useState(""), 2), searchInput = _y[0], setSearchInput = _y[1];
    var _z = __read(useState([
        {
            order: 4,
            condition: "where",
            field: "is_avis",
            operator: "is",
            value: true,
        },
        {
            order: 5,
            condition: "and",
            field: "visible_publiquement",
            operator: "is",
            value: true,
        },
        {
            order: 6,
            condition: "and",
            field: "entreprise.id",
            operator: "greater",
            value: 0,
        },
        {
            order: 7,
            condition: "and",
            field: "entreprise.status",
            operator: "is",
            value: ["actived", "imported"],
        },
    ]), 2), rows = _z[0], setRows = _z[1];
    var _0 = __read(useState(false), 2), projectsIsLoading = _0[0], setProjectsIsLoading = _0[1];
    var _1 = __read(useState(false), 2), filtersIsLoading = _1[0], setFiltersIsLoading = _1[1];
    //State for infinit scroll
    var _2 = __read(useState(false), 2), hasMoreData = _2[0], setHasMoreData = _2[1];
    var _3 = __read(useState(""), 2), nextProjects = _3[0], setNextProjects = _3[1];
    //Projects Count
    var _4 = __read(React.useState(0), 2), count = _4[0], setCount = _4[1];
    // Pagination State
    var _5 = __read(React.useState(0), 2), page = _5[0], setPage = _5[1];
    var _6 = __read(React.useState(1), 2), pageCount = _6[0], setPageCount = _6[1];
    useEffect(function () {
        // fetchProjects(yearRange);
    }, [yearRange]);
    // Get fields
    useEffect(function () {
        fetchField();
    }, []);
    var fetchField = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, projectsTypes, projectsJSJCTypes, updatedProjectsTypes, updatedProjectsJSJCTypes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFiltersIsLoading(true);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/fields/?table=Project&context=filters&no_fetching"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    projectsTypes = data.filter(function (obj) { return obj.field_name == "project_type"; });
                    projectsJSJCTypes = data.filter(function (obj) { return obj.field_name == "type_de_projet_je_suis_je_cherche"; });
                    updatedProjectsTypes = projectsTypes[0].choices_labeled.map(function (item, index) {
                        return { id: index, nom: item[1], slug: item[0] };
                    });
                    updatedProjectsJSJCTypes = projectsJSJCTypes[0].choices_labeled.map(function (item) {
                        return { id: item[0], nom: item[1] };
                    });
                    setProjectsTypes(updatedProjectsTypes);
                    setProjectsJesuisJechercheTypes(updatedProjectsJSJCTypes);
                    setFiltersIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Get projects
    useEffect(function () {
        var _a;
        //order by first selected filter
        sortArray(rows, {
            by: ["order"],
        });
        //check if the first element is not "where"
        if (rows.length > 0) {
            rows[0].condition = "and";
        }
        for (var i = 1; i < rows.length; i++) {
            //this condition is for timeline
            if (rows[i].field !== endDateField) {
                if (rows[i].field !== rows[i - 1].field) {
                    rows[i].condition = "and";
                }
            }
        }
        var searchData = {
            query: searchInput !== searchedTerm ? searchInput : searchedTerm,
            page: page + 1,
            filters: [
                {
                    Project: {
                        sort: (_a = {},
                            _a[startDateField] = {
                                order: "desc",
                            },
                            _a),
                        conditions: rows,
                    },
                },
            ],
        };
        if (advSearch) {
            var newRows = rows.filter(function (e) {
                return e.field != "is_avis";
            });
            if (rows.length != newRows.length) {
                setRows(newRows);
                setRowsSearchData({ conditions: newRows });
            }
            else {
                setRowsSearchData({ conditions: rows });
            }
        }
        if (!advSearch) {
            if (searchData.filters[0].Project.conditions.length <= 4) {
                searchData.filters[0].Project.conditions = [
                    {
                        order: 4,
                        condition: "where",
                        field: "is_avis",
                        operator: "is",
                        value: true,
                    },
                    {
                        order: 5,
                        condition: "and",
                        field: "visible_publiquement",
                        operator: "is",
                        value: true,
                    },
                    {
                        order: 6,
                        condition: "and",
                        field: "entreprise.id",
                        operator: "greater",
                        value: 0,
                    },
                    {
                        order: 7,
                        condition: "and",
                        field: "entreprise.status",
                        operator: "is",
                        value: ["actived", "imported"],
                    },
                ];
            }
            fetchProjects(searchData);
        }
    }, [rows, page, searchedTerm, randQuery]);
    //Fetch projects
    var fetchProjects = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/".concat(langCode, "/search/"); }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                                headers: {
                                    "X-CSRFToken": CSRF_TOKEN,
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                                body: JSON.stringify(data),
                            })];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.json()];
                    }
                });
            });
        }
        var CSRF_TOKEN;
        return __generator(this, function (_a) {
            CSRF_TOKEN = Cookies.get("csrftoken");
            // ===---
            if (!projectsIsLoading) {
                setProjectsIsLoading(true);
                data["filters"][0]["Project"]["aggregations"] = {
                    montant: "max",
                };
                postData("/".concat(langCode, "/api/search/"), data)
                    .then(function (data) {
                    var newProjects = data.results.map(function (data) {
                        return data.result;
                    });
                    var nbrPages = data.count / 10;
                    setPageCount(Math.ceil(nbrPages));
                    data.next ? setHasMoreData(true) : setHasMoreData(false);
                    setNextProjects(data.next);
                    if (view == "calendar" && data.count == count && page > 0) {
                        setProjects(function (prevProject) { return __spreadArray(__spreadArray([], __read(prevProject), false), __read(newProjects), false); });
                    }
                    else {
                        setProjects(newProjects);
                    }
                    setCount(data.count);
                    if (!montantMaxValue) {
                        setMaxValue(data.aggregations[0]["projects-index"].montant);
                        setMontantMaxValue(data.aggregations[0]["projects-index"].montant);
                    }
                    setProjectsIsLoading(false);
                })
                    .catch(function () {
                    setProjectsIsLoading(false);
                });
            }
            return [2 /*return*/];
        });
    }); };
    function handleYearChange(e) {
        setYearRange(e.target.value);
    }
    var handleView = function () {
        if (view === "list") {
            setPage(0);
            setView("calendar");
        }
        else {
            setPage(0);
            setView("list");
            var updatedRows = rows.filter(function (obj) {
                return obj.field !== endDateField &&
                    obj.field !== startDateField;
            });
            setRows(updatedRows);
        }
    };
    //Search Projects
    var handleSearchInput = function (e, tab) {
        if (tab === void 0) { tab = 1; }
        if (tab != 1)
            setAdvSearch(true);
        setSearchInput(e.target.value);
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    var searchProjects = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    //handle category change
    var handleCategoryChange = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedCategories
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedCategories.includes(x); }));
        var newCategory = {
            order: 99999999,
            condition: "or",
            field: "categorie_projet.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedCategories.length) {
            setSelectedCategories(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newCategory], false);
            });
            if (rows.some(function (obj) { return Object.values(obj).includes("categorie_projet.id"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "categorie_projet.id"; });
                newCategory.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newCategory.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_1 = selectedCategories
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedCategories.includes(x); }));
            setSelectedCategories(item);
            setRows(function () {
                return rows.filter(function (item) {
                    return item.value !== removedOption_1[0].value;
                });
            });
        }
    };
    //Handle project type change
    var handleProjectTypeChange = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedProjectsTypes
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedProjectsTypes.includes(x); }));
        var newProjectType = {
            order: 99999999,
            condition: "or",
            field: "project_type",
            operator: "contain",
            id: latestSelected[0].value,
            value: latestSelected[0].slug.substr(0, 4),
        };
        if (item.length > selectedProjectsTypes.length) {
            setSelectedProjectsTypes(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newProjectType], false);
            });
            if (rows.some(function (obj) { return Object.values(obj).includes("project_type"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "project_type"; });
                newProjectType.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newProjectType.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_2 = selectedProjectsTypes
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedProjectsTypes.includes(x); }));
            setSelectedProjectsTypes(item);
            setRows(function () {
                return rows.filter(function (item) {
                    return item.id !== removedOption_2[0].value;
                });
            });
        }
    };
    //handle price change
    var handlePriceChange = function (e) {
        setPage(0);
        setMinValue(e.minValue);
        setMaxValue(e.maxValue);
        var newPrice = {
            order: 0,
            condition: "or",
            field: "montant",
            operator: "between",
            value: ["".concat(e.minValue), "".concat(e.maxValue)],
        };
        if (montantMaxValue !== e.maxValue || e.minValue > 0) {
            if (rows.findIndex(function (row) { return row.field === "montant"; }) == -1) {
                setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), [newPrice], false); });
            }
            else {
                var rowsCopy = __spreadArray([], __read(rows), false);
                var rowIndex = rowsCopy.findIndex(function (row) { return row.field === "montant"; });
                if ((rowIndex !== -1 &&
                    rowsCopy[rowIndex].value[1] !== newPrice.value[1]) ||
                    rowsCopy[rowIndex].value[0] !== newPrice.value[0]) {
                    rowsCopy[rowIndex] = __assign(__assign({}, rowsCopy[rowIndex]), { value: newPrice.value });
                    setRows(rowsCopy);
                }
            }
        }
        else {
            var updatedRows = rows.filter(function (item) { return item.field !== "montant"; });
            if (rows.some(function (item) { return item.field == 'montant'; })) {
                setRows(updatedRows);
            }
        }
    };
    var handleMinPriceChange = function (e) {
        setPage(0);
        var newPrice = {
            order: 0,
            condition: "or",
            field: "montant",
            operator: "between",
            value: ["".concat(e.target.value), "".concat(maxValue)],
        };
        setMinValue(e.target.value);
        if (e.target.value > maxValue) {
            setMinValue(maxValue);
        }
        else {
            setMinValue(e.target.value);
        }
        if (e.target.value > 0) {
            if (rows.findIndex(function (row) { return row.field === "montant"; }) == -1) {
                setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), [newPrice], false); });
            }
            else {
                // Update the value of an object in the array
                var updatedArray = rows.map(function (obj) {
                    if (obj.field === "montant") {
                        return __assign(__assign({}, obj), { value: ["".concat(e.target.value), "".concat(maxValue)] });
                    }
                    return obj; // return the unchanged object
                });
                setRows(updatedArray);
            }
        }
        else {
            var updatedRows = rows.filter(function (item) { return item.field !== "montant"; });
            setRows(updatedRows);
        }
    };
    var handleMaxPriceChange = function (e) {
        setPage(0);
        var newPrice = {
            order: 0,
            condition: "or",
            field: "montant",
            operator: "between",
            value: ["".concat(minValue), "".concat(e.target.value)],
        };
        if (e.target.value < minValue) {
            setMaxValue(minValue);
        }
        else {
            setMaxValue(e.target.value);
        }
        if (e.target.value != montantMaxValue) {
            if (rows.findIndex(function (row) { return row.field === "montant"; }) == -1) {
                setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), [newPrice], false); });
            }
            else {
                // Update the value of an object in the array
                var updatedArray = rows.map(function (obj) {
                    if (obj.field === "montant") {
                        return __assign(__assign({}, obj), { value: ["".concat(minValue), "".concat(e.target.value)] });
                    }
                    return obj; // return the unchanged object
                });
                setRows(updatedArray);
            }
        }
        else {
            var updatedRows = rows.filter(function (item) { return item.field !== "montant"; });
            setRows(updatedRows);
        }
    };
    //Handle mrc Change
    var handleMrcChange = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedMrc
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedMrc.includes(x); }));
        var newMrc = {
            order: 99999999,
            condition: "or",
            field: "adresse.mrc.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedMrc.length) {
            setSelectedMrc(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newMrc], false);
            });
            if (rows.some(function (obj) { return Object.values(obj).includes("adresse.mrc.id"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "adresse.mrc.id"; });
                newMrc.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newMrc.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_3 = selectedMrc
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedMrc.includes(x); }));
            setSelectedMrc(item);
            setRows(function () {
                return rows.filter(function (item) {
                    return item.value !== removedOption_3[0].value;
                });
            });
        }
    };
    //Handle region Change
    var handleRgionChange = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedRegion
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedRegion.includes(x); }));
        var newRegion = {
            order: 99999999,
            condition: "or",
            field: "adresse.region_adminsitrative.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedRegion.length) {
            setSelectedRegion(item);
            if (rows.some(function (obj) {
                return Object.values(obj).includes("adresse.region_adminsitrative.id");
            })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "adresse.region_adminsitrative.id"; });
                newRegion.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newRegion.order = bigOrder + 1;
            }
            if (typeof newRegion.value === "object") {
                setRows(function (prevStat) {
                    return __spreadArray(__spreadArray([], __read(prevStat), false), __read(newRegion.value.map(function (v) { return (__assign(__assign({}, newRegion), { value: v })); })), false);
                });
            }
            else {
                setRows(function (prevStat) {
                    return __spreadArray(__spreadArray([], __read(prevStat), false), [newRegion], false);
                });
            }
        }
        else {
            //get the removed option
            var removedOption_4 = selectedRegion
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedRegion.includes(x); }));
            setSelectedRegion(item);
            if (typeof removedOption_4[0].value === 'object') {
                setRows(function (prev) {
                    return prev.filter(function (item) {
                        return !removedOption_4[0].value.includes(item.value);
                    });
                });
            }
            else {
                setRows(function (prev) {
                    return prev.filter(function (item) {
                        return item.value !== removedOption_4[0].value;
                    });
                });
            }
        }
    };
    // highest order
    function getHighestOrder(array) {
        var highestPrice = 99999999;
        for (var i = 0; i < array.length; i++) {
            if (array[i].order > highestPrice) {
                highestPrice = array[i].order;
            }
        }
        return highestPrice;
    }
    //Range in time line view
    var handleRangeChange = function (newValues) {
        setPage(0);
        var newYear = [
            {
                order: 0,
                condition: "where",
                field: startDateField,
                operator: "smaller",
                value: "".concat(newValues[0]),
            },
            {
                order: 0,
                condition: "or",
                field: startDateField,
                operator: "between",
                value: ["".concat(newValues[0], "-01-01"), "".concat(newValues[0], "-12-31")],
            },
            {
                order: 3,
                condition: "and",
                field: endDateField,
                operator: "greater",
                value: "".concat(newValues[0], "-01-01"),
            },
        ];
        setRangeValue(newValues);
        if (rows.findIndex(function (row) { return row.field === startDateField; }) == -1) {
            setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), __read(newYear), false); });
        }
        else {
            // Update the value of an object in the array
            var updatedArray = rows.map(function (obj) {
                if (obj.field === startDateField &&
                    obj.operator == "between") {
                    return __assign(__assign({}, obj), { value: ["".concat(newValues[0], "-01-01"), "".concat(newValues[0], "-12-31")] });
                }
                if ((obj.field == endDateField && (obj.operator == "smaller" ||
                    obj.operator == "greater"))) {
                    return __assign(__assign({}, obj), { value: "".concat(newValues[0], "-01-01") });
                }
                if ((obj.field == startDateField && (obj.operator == "smaller" ||
                    obj.operator == "greater"))) {
                    return __assign(__assign({}, obj), { value: "".concat(newValues[0], "-01-01") });
                }
                return obj; // return the unchanged object
            });
            setRows(updatedArray);
        }
    };
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    //Reset filters
    var resertFilters = function () {
        setRows([
            {
                order: 4,
                condition: "where",
                field: "is_avis",
                operator: "is",
                value: true,
            },
            {
                order: 5,
                condition: "and",
                field: "visible_publiquement",
                operator: "is",
                value: true,
            },
            {
                order: 6,
                condition: "and",
                field: "entreprise.id",
                operator: "greater",
                value: 0,
            },
            {
                order: 7,
                condition: "and",
                field: "entreprise.status",
                operator: "is",
                value: ["actived", "imported"],
            },
        ]);
        setPage(0);
        setSelectedMrc([]);
        setSelectedProjectsTypes([]);
        setSelectedCategories([]);
        setSelectedRegion([]);
        setRandQuery(Math.random());
    };
    var loadNewProjects = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!hasMoreData) {
                return [2 /*return*/];
            }
            setProjectsIsLoading(true);
            nextProjects ? setHasMoreData(true) : setHasMoreData(false);
            nextProjects ? setPage(function (prevPage) { return prevPage + 1; }) : "";
            setProjectsIsLoading(false);
            return [2 /*return*/];
        });
    }); };
    return (_jsx(ProjectsContext.Provider, __assign({ value: {
            MONTHS_DATA_FR: MONTHS_DATA_FR,
            MONTHS_DATA_EN: MONTHS_DATA_EN,
            view: view,
            yearRange: yearRange,
            projects: projects,
            selectedProjectsTypes: selectedProjectsTypes,
            count: count,
            projectsIsLoading: projectsIsLoading,
            filtersIsLoading: filtersIsLoading,
            projectsTypes: projectsTypes,
            projectsJesuisJechercheTypes: projectsJesuisJechercheTypes,
            categories: categories,
            selectedCategories: selectedCategories,
            minValue: minValue,
            maxValue: maxValue,
            montantMaxValue: montantMaxValue,
            mrc: mrc,
            region: region,
            selectedMrc: selectedMrc,
            selectedRegion: selectedRegion,
            rangeValue: rangeValue,
            page: page,
            pageCount: pageCount,
            rows: rows,
            rowsSearchData: rowsSearchData,
            hasMoreData: hasMoreData,
            loadNewProjects: loadNewProjects,
            setAdvSearch: setAdvSearch,
            setRangeValue: setRangeValue,
            setRows: setRows,
            handleView: handleView,
            handleYearChange: handleYearChange,
            setSelectedProjectsTypes: setSelectedProjectsTypes,
            handleProjectTypeChange: handleProjectTypeChange,
            handleSearchInput: handleSearchInput,
            searchProjects: searchProjects,
            setSearchedTerm: setSearchedTerm,
            handleKeyDown: handleKeyDown,
            handleCategoryChange: handleCategoryChange,
            handlePriceChange: handlePriceChange,
            handleMinPriceChange: handleMinPriceChange,
            handleMaxPriceChange: handleMaxPriceChange,
            handleMrcChange: handleMrcChange,
            handleRgionChange: handleRgionChange,
            handleRangeChange: handleRangeChange,
            handlePageClick: handlePageClick,
            resertFilters: resertFilters,
            setRandQuery: setRandQuery,
            setCategories: setCategories,
            setMrc: setMrc,
            setRegion: setRegion,
        } }, { children: children })));
};
export default ProjectsContext;
